import React, { Fragment, useState } from "react";
import LoaderComponent from "./UI/Loaders/LoaderComponent";
import { Validator } from "../../utils/validator";
//import { initRecaptchaForm } from '../../assets/js/helper';
import axios from "axios";

const ContactForm = ({title}) => {

	const [state, setState] = useState({
		name: "",
		surname: "",
		phone: "",
		email: "",
		message: "",
		loading: false,
		form_errors: {}
	});

	const { name, surname, phone, email, message, form_errors, loading } = state;

	// useEffect(() => {
	// 	initRecaptchaForm();
	// }, []);

	const changeInputHandler = (name, value) => setState(prevState => ({...prevState, [name]: value}));
	
	const submitFormHandler = (e) => {
		e.preventDefault();
		let validated_data = Validator();
		validated_data.addField("name", name, "required|max:50");
		validated_data.addField("surname", surname, "required|max:50");
		validated_data.addField("email", email, "required|min:4|max:50|email");
		validated_data.addField("phone", phone, "required|min:7|max:11|digits");
		validated_data.addField("message", message, "max:500");

        //let recaptcha = document.getElementById("recaptchaValidation").value;

		if(!validated_data.validate()) {
			setState(prevState => ({...prevState, form_errors: validated_data.getErrors()}));
			return;
		}

        setState(prevState => ({...prevState, loading: true}));

		let bodyFormData = new FormData(); 
		bodyFormData.append("name", validated_data.getValue("name"));
		bodyFormData.append("surname", validated_data.getValue("surname"));
		bodyFormData.append("email", validated_data.getValue("email"));
		bodyFormData.append("phone", validated_data.getValue("phone"));
		bodyFormData.append("message", validated_data.getValue("message"));
				
		const request_options = {
			headers: {'content-type': 'application/x-www-form-urlencoded'}
		};

		axios.post("https://www.pratiche2m.it/mail/", bodyFormData, request_options).then(response => {
			console.log(response.data);
			if (parseInt(response.data.is_valid) === 1) {
				alert("Il modulo è stato completato con successo!");
				setState(prevState => ({
					...prevState, name: "", surname: "", phone: "", email: "", message: "", form_errors: {}, loading: false
				}));
			} else {
				setState(prevState => ({...prevState, loading: false, form_errors: response.data.errors}))
			}
		}).catch(error => console.log(error));
		
	};

	return (
		<Fragment>
	        <h3>{title}</h3>

	        <form className="form-template" onSubmit={submitFormHandler}>
	            <div className="form-corner" />

	            <label htmlFor="name">NOME</label>
	            <input
	            	type="text" 
	            	name="name" 
	            	id="name" 
	            	autoCorrect="off" 
	            	onChange={(e) => changeInputHandler("name", e.target.value)} 
            	/>
	            {form_errors.name && (<div className="error">{form_errors.name}</div>)}

	            <label htmlFor="surname">COGNOME</label>
	            <input 
	            	type="text" 
	            	name="surname" 
	            	id="surname" 
	            	autoCorrect="off"
	            	onChange={(e) => changeInputHandler("surname", e.target.value)}  
            	/>
            	{form_errors.surname && (<div className="error">{form_errors.surname}</div>)}


	            <label htmlFor="phone">TELEFONO</label>
	            <input 
	            	type="text" 
	            	name="phone" 
	            	id="phone" 
	            	autoCorrect="off" 
	            	onChange={(e) => changeInputHandler("phone", e.target.value)} 
            	/>
	            {form_errors.phone && (<div className="error">{form_errors.phone}</div>)}

	            <label htmlFor="email">EMAIL</label>
	            <input 
	            	type="text" 
	            	name="email" 
	            	id="email" 
	            	autoCorrect="off" 
	            	onChange={(e) => changeInputHandler("email", e.target.value)} 
            	/>
	            {form_errors.email && (<div className="error">{form_errors.email}</div>)}

	            <label htmlFor="message">MESSAGGIO</label>
	            <textarea 
	            	name="message" 
	            	id="message" 
	            	cols="30" 
	            	rows="10" 
	            	autoCorrect="off"
	            	onChange={(e) => changeInputHandler("message", e.target.value)} 
            	/>
	            {form_errors.message && (<div className="error">{form_errors.message}</div>)}

	            {/*<div className="g-recaptcha" data-sitekey="6LdnlEQUAAAAAIkXZOfhbc2n0aGb6P7PfYrvpxX_"></div>*/}
	            {/*<div className="error recaptcha"></div>*/}

	            {loading ? (
	            	<LoaderComponent />
            	) : (
	            	<button className="contact-submit" type="submit">INVIA MESSAGGIO</button>
            	)}
	        </form>
	    </Fragment>
	);

};

export default ContactForm;