import React from 'react';
import Breadcrumb from "../layout/Breadcrumb";
import SearchForInfo from "../layout/SearchForInfo";
import ContactForm from "../layout/ContactForm";
import Footer from '../footer';
import { scrollToTop, changeTitle } from '../../assets/js/helper';

const agenziaPerLaRiscossione = (props) => {

    const breadcrumb = "AGENZIA PER LA RISCOSSIONE";
    const activePage = "agenzia-per-la-riscossione";

    scrollToTop();
    changeTitle(activePage);

    const servicesList = {
        "agenzia-per-la-riscossione": {
            icon: "riscossione.png",
            list: [
                "Estratto di ruolo",
                "Relate di notifica",
                "Pagamento cartelle",
                "Rateizzi",
                "Rimborsi",
                "Richiesta RAV"
            ]
        }
    };

    return (
        <div>
            <div className="page-container-hero">
                <Breadcrumb breadcrumbTitle={breadcrumb} />

                <div className="hero-inner-container servizi">
                    <div className="container">
                        <SearchForInfo />
                    </div>
                    <div className="page-container">
                        <div className="hero-row">
                            <div className="hero-column hero-col-12">
                                <p>La nostra agenzia di disbrigo pratiche ha acquisto praticità ed esperienza anche nella richiesta di documenti all'Agenzia per la Riscossione, come il pagamento di cartelle, gli estratti di ruolo o le richieste RAV. Contattaci per avere maggiori informazioni su tutti i servizi che possiamo offrirti.</p>
                            </div>
                        </div>
                        <div className="hero-row">
                            <div className="contact-information hero-column hero-col-6">
                                <div className="services-list">
                                    <div className="list-header row">
                                        <div className="hero-col-3">
                                            <img src={"/assets/images/" + servicesList[activePage].icon} alt="service icon"/>
                                        </div>
                                        <div className="hero-col-8">
                                            <h3>SERVIZI</h3>
                                        </div>
                                    </div>

                                    <ul>
                                        {servicesList[activePage].list.map((item, i) => (
                                            <div key={i}>
                                                <li> <span>{item}</span></li>
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div className="contact-form hero-column hero-col-6">
                                <ContactForm title="COMPILA UN MODULO PER MAGGIORI INFORMAZIONI" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="textarea-clearfix hidden"></div>
            <span className="contact"></span>
            <Footer type="secondary"/>
        </div>
    );
}

export default agenziaPerLaRiscossione;