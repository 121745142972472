import React from 'react';
import Breadcrumb from "../layout/Breadcrumb";
import SearchForInfo from "../layout/SearchForInfo";
import ContactForm from "../layout/ContactForm";
import Footer from '../footer';
import { scrollToTop, changeTitle } from '../../assets/js/helper';

const agenziaDelleEntrate = (props) => {

    const breadcrumb = "AGENZIA DELLE ENTRATE";
    const activePage = "agenzia-delle-entrate";

    scrollToTop();
    changeTitle(activePage);

    const servicesList = {
        "agenzia-delle-entrate": {
            icon: "entrate.png",
            list: [
                "Contratti di locazione",
                "Contratti di comodato",
                "Registrazione atti e verbali",
                "Apertura partita iva",
                "Certificati reddituali",
                "Dichiarazione di successione",
                "Codice fiscale attribuzione e/o duplicato",
                "Certificato reddituale",
                "Regolarità fiscale"
            ]
        },
    };

   
    return (
        <div>
            <div className="page-container-hero">
                <Breadcrumb breadcrumbTitle={breadcrumb} />
                <div className="hero-inner-container servizi">
                    <div className="container">
                        <SearchForInfo />
                    </div>
                    <div className="page-container">
                        <div className="hero-row">
                            <div className="hero-column hero-col-12">
                                <p>Pratiche 2M può aiutarti con tutte le attività relative all' Agenzia delle Entrate, dai certificati alle dichiarazioni, dall'apertura della Partita IVA ai contratti. Con noi, ogni pratica sarà espletata nel minor tempo possibile, grazie ad un team di collaboratori fortemente qualificati e sempre disponibili.</p>
                            </div>
                        </div>
                        <div className="hero-row">
                            <div className="contact-information hero-column hero-col-6">
                                <div className="services-list">
                                    <div className="list-header row">
                                        <div className="hero-col-3">
                                            <img src={"/assets/images/" + servicesList[activePage].icon} alt="service icon"/>
                                        </div>
                                        <div className="hero-col-8">
                                            <h3>SERVIZI</h3>
                                        </div>
                                    </div>

                                    <ul>
                                        {servicesList[activePage].list.map((item, i) => (
                                            <div key={i}>
                                                <li> <span>{item}</span></li>
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div className="contact-form hero-column hero-col-6">
                                <ContactForm title="COMPILA UN MODULO PER MAGGIORI INFORMAZIONI" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="textarea-clearfix hidden"></div>
            <span className="contact"></span>
            <Footer type="secondary"/>
        </div>
    );
};

export default agenziaDelleEntrate;