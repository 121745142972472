import React from "react";
import Breadcrumb from "../layout/Breadcrumb";
import SearchForInfo from "../layout/SearchForInfo";
import ContactForm from "../layout/ContactForm";
import { scrollToTop, changeTitle } from "../../assets/js/helper";
import Footer from "../footer";

const VolantinaggioPage = (props) => {

    const breadcrumb = "VOLANTINAGGIO";
    const activePage = "volantinaggio";

	scrollToTop();
    changeTitle(activePage);

	return (
		<div>
			<div className="page-container-hero">
				<Breadcrumb breadcrumbTitle={breadcrumb} />
				<div className="hero-inner-container servizi">
                        <div className="container">
                            <SearchForInfo />
                        </div>
                        <div className="page-container">
                            <div className="hero-row">
                                <div className="hero-column hero-col-12">
                                    <p>Tramite la nostra agenzia, è possibile richiedere alla Camera di Commercio tutti i documenti di cui si ha bisogno, dalle visure ai bilanci, dai certificati ai documenti aziendali. Con Pratiche 2M potrai ottenere tutto in pochissimo tempo, evitando qualsiasi spostamento. Contattaci per ricevere maggiori informazioni. </p>
                                </div>
                            </div>
                            <div className="hero-row">
                                <div className="contact-information hero-column hero-col-6">
                                    <div className="services-list">
                                        <div className="list-header row">
                                            <div className="hero-col-12">
                                                <h3>VOLANTINAGGIO</h3>
                                            </div>
                                            <p>Grazie alla collaborazione società leader del settore  e all'accurato controllo che noi effettuiamo  in merito alla tipologia di distribuzione, nonché la grafica e la stampa richiesta dal Cliente, garantiamo la massima affidabilità e capillarità.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="contact-form hero-column hero-col-6">
                                	<ContactForm title="COMPILA UN MODULO PER MAGGIORI INFORMAZIONI" />
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="textarea-clearfix hidden"></div>
                <span className="contact"></span>
                <Footer type="secondary"/>
			</div>
		</div>
	);

};

export default VolantinaggioPage;