import React, { Fragment } from "react";
import { Link } from 'react-router-dom';

const Breadcrumb = ({breadcrumbTitle}) => {

	return (
		<Fragment>
			<div className="page-header-services" />
			<div className="breadcrumb-container">
		        <div className="main-breadcrumb">
		            <ul className="breadcrumb-list" itemScope itemType="http://schema.org/BreadcrumbList">
		                <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
		                    <i className="fa fa-home" />
		                    <Link to="/"><span className="front-breadcrumb" itemProp="name"> Home </span></Link>
		                    <meta itemProp="position" content="1" />
		                </li>
		                <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
		                    <span className="actual-breadcrumb" itemProp="name"> {breadcrumbTitle} </span>
		                    <meta itemProp="position" content="2" />
		                </li>
		            </ul>
		        </div>
		        <div className="title-breadcrumb">
		            <h2>{breadcrumbTitle}</h2>
		        </div>
		    </div>
        </Fragment>
    );

};

export default Breadcrumb;