import React, { useEffect } from "react";

import { attemptSearch } from '../../assets/js/helper';

const SearchForInfo = () => {
	
	useEffect(() => {
		document.getElementById('search-box').addEventListener('keyup', event => {
            if ((document.getElementById('search-box').value).length >= 3) {
                attemptSearch(document.getElementById('search-box').value);
            } else {
                document.getElementsByClassName('search-results')[1].classList.remove("open");
            }
        });
	}, []);

	return (
		<div className="search-container">
	        <form className="search-services-form">
	            <input id="search-box" className="search-services" placeholder="Inserisci il nome del documento o del servizo che stai cercando..."/>
	        </form>
	        <div className="search-results" />
	    </div>
	);
};

export default SearchForInfo;