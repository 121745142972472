import React from 'react';
import Breadcrumb from "../layout/Breadcrumb";
import SearchForInfo from "../layout/SearchForInfo";
import ContactForm from "../layout/ContactForm";
import Footer from '../footer';
import { scrollToTop, changeTitle } from '../../assets/js/helper';

const serviziDigitali = (props) => {

    const breadcrumb = "SERVIZI DIGITALI";
    const activePage = "servizi-digitali";

    scrollToTop();
    changeTitle(activePage);
        
    const servicesList = {
        "servizi-digitali": {
            icon: "digitali.png",
            list: [
                "PEC",
                "Firma digitale / CNS",
                "Smart card",
                "Aruba Key",
                "Codice LEI",
                "Fatturazione elettronica",
                "Conservazione sostitutiva"
            ]
        },
    };

    return (
        <div>
            <div className="page-container-hero">
                <Breadcrumb breadcrumbTitle={breadcrumb} />

                <div className="hero-inner-container servizi">
                    <div className="container">
                        <SearchForInfo />
                    </div>
                    <div className="page-container">
                        <div className="hero-row">
                            <div className="hero-column hero-col-12">
                                <p>Pratiche 2M può darti un considerevole supporto anche in servizi digitali come la richiesta di posta elettronica certificata, la firma digitale, la fatturazione elettronica e così via. Per maggiori informazioni in merito, non esitare a compilare il modulo e contattarci.</p>
                            </div>
                        </div>
                        <div className="hero-row">
                            <div className="contact-information hero-column hero-col-6">
                                <div className="services-list">
                                    <div className="list-header row">
                                        <div className="hero-col-3">
                                            <img src={"/assets/images/" + servicesList[activePage].icon} alt="service icon"/>
                                        </div>
                                        <div className="hero-col-8">
                                            <h3>SERVIZI</h3>
                                        </div>
                                    </div>

                                    <ul>
                                        {servicesList[activePage].list.map((item, i) => (
                                            <div key={i}>
                                               <li> <span>{item}</span></li>
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div className="contact-form hero-column hero-col-6">
                                <ContactForm title="COMPILA UN MODULO PER MAGGIORI INFORMAZIONI" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="textarea-clearfix hidden"></div>
            <span className="contact"></span>
            <Footer type="secondary"/>
        </div>
    );
};

export default serviziDigitali;