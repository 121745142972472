import React from 'react';
import Breadcrumb from "../layout/Breadcrumb";
import SearchForInfo from "../layout/SearchForInfo";
import Footer from '../footer';
import { scrollToTop, changeTitle } from '../../assets/js/helper';

const Imprese = (props) => {

    const breadcrumb = "Chi siamo";
    const activePage = "chi-siamo";


    scrollToTop();
    changeTitle(activePage);

    return (
        <div>
            <div className="page-container-hero">
                <Breadcrumb breadcrumbTitle={breadcrumb} />

                <div className="hero-inner-container">
                    <div className="container">
                        <SearchForInfo />
                    </div>

                    <div className="chi-siamo-wrapper">
                        <div className="banner-chi-siamo">
                            <h2>da oggi svolgerai</h2>
                            <h2>la tue pratiche</h2>
                            <h2>in soli <span className="heading-sp-w">2 minuti</span></h2>
                        </div>

                        <div className="description">
                        	La società 2M srl nasce dall’idea di Mario e Massimo Santoro, due professionisti che nel 2010 decidono di
							costituire un’ Agenzia di disbrigo pratiche amministrative che eroga i propri servizi a favore di
							professionisti, aziende e privati su tutto il territorio nazionale. Grazie ad una efficiente struttura
							organizzativa e ad un team altamente qualificato, l’Agenzia 2M, riesce ad espletare tutte le pratiche
							burocratiche in tempi rapidi e costi ridotti facendo risparmiare ai propri clienti tempo e denaro.
							Con un approccio completamente orientato al raggiungimento dei risultati, alla soddisfazione del cliente e
							con un continuo dialogo con la pubblica amministrazione, la società 2M riesce ad espletare ogni tipo di
							adempimenti burocratici anche i più complessi    
                        </div>
                    </div>

                    <hr className="hr-hero"/>

                    <div className="heading chi-siamo">
                        <h2>PERCHÈ SCEGLIERE PRATICHE 2M</h2>
                    </div>

                    <div className="images chi-siamo">
                        <div>
                            <img src="/assets/images/trasparenza.jpg" alt="Trasparenza"/>
                            <h3 className="heading">TRASPARENZA</h3>
                            <p className="description">Il nostro team di professionisti è sempre pronto a rispondere alle tue domande.</p>
                        </div>
                        <div>
                            <img src="/assets/images/vantaggi.jpg" alt="Vantaggi"/>
                            <h3 className="heading">VANTAGGI</h3>
                            <p className="description">Eviterai di perdere tempo prezioso; i nostri consulenti ti assisteranno anche telefonicamente.</p>
                        </div>
                        <div>
                            <img src="/assets/images/velocita.jpg" alt="Velocità"/>
                            <h3 className="heading">VELOCITÀ</h3>
                            <p className="description">Il nostro servizio è in grado di soddisfare le vostre richieste anche in poche ore.</p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer type="secondary"/>
        </div>
    );
};

export default Imprese;