import React from 'react';
import Breadcrumb from "../layout/Breadcrumb";
import SearchForInfo from "../layout/SearchForInfo";
import ContactForm from "../layout/ContactForm";
import Footer from '../footer';
import Map from '../map';
import { scrollToTop, changeTitle } from '../../assets/js/helper';

const Contatti = (props) => {

    const breadcrumb = "Contatti";
    const activePage = "contatti";

    scrollToTop();
    changeTitle(activePage);

    return (
        <div>
            <div className="page-container-hero">
                <Breadcrumb breadcrumbTitle={breadcrumb} />

                <Map/>

                <div className="hero-inner-container contact">
                    <div className="container">
                        <SearchForInfo />
                    </div>
                    <div className="page-container hero-row">
                        <div className="contact-form hero-column hero-col-6">
                            <ContactForm title="INVIA UN MESSAGGIO" />
                        </div>
                        <div className="contact-information hero-column hero-col-6">
                            <h3>RECAPITI</h3>
                            
                            <div className="hero-row">
                                <div className="hero-column horizontal-align hero-col-2">
                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                </div>
                                <div className="hero-column vertical-align hero-col-10">
                                    <div>Via Cervantes 55.27 scala E primo piano</div>
                                    <div>80133 - Napoli</div>
                                    <div>Ampia possibilità di parcheggio nelle vicinanze</div>
                                </div>
                            </div>

                            <div className="hero-row">
                                <div className="hero-column horizontal-align hero-col-2">
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                </div>
                                <div className="hero-column vertical-align hero-col-10">
                                    <div>Tel. +39 081 198 12836</div>
                                </div>
                            </div>

                            <div className="hero-row">
                                <div className="hero-column horizontal-align hero-col-2">
                                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                </div>
                                <div className="hero-column vertical-align hero-col-10">
                                    <div><a href="mailto:info@pratiche2m.it"> info@pratiche2m.it </a></div>
                                </div>
                            </div>

                            <hr className="hr-hero2"/>

                            <div className="hero-row social-links">
                                <div className="hero-column justified hero-col-offset-1 hero-col-2">
                                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook fa-2" aria-hidden="true"></i></a>
                                </div>
                                <div className="hero-column justified hero-col-2">
                                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter fa-2" aria-hidden="true"></i></a>
                                </div>
                                <div className="hero-column justified hero-col-2">
                                    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin fa-2" aria-hidden="true"></i></a>
                                </div>
                                <div className="hero-column justified hero-col-2">
                                    <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube-play fa-2" aria-hidden="true"></i></a>
                                </div>
                                <div className="hero-column justified hero-col-2">
                                    <a href="https://plus.google.com" target="_blank" rel="noopener noreferrer"><i className="fa fa-google-plus fa-2" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="textarea-clearfix hidden"></div>
            <span className="contact"></span>
            <Footer type="contact"/>
        </div>
    );
};

export default Contatti;