import React from 'react';
import Breadcrumb from "../layout/Breadcrumb";
import ContactForm from "../layout/ContactForm";
import Footer from '../footer';
import { scrollToTop, changeTitle } from '../../assets/js/helper';

const fatturazioneElettronica = (props) => {

    const breadcrumb = "Fatturazione Elettronica";
    const activePage = "fatturazione-elettronica";

    scrollToTop();
    changeTitle(activePage);

    return (
        <div>
            <div className="page-container-hero">
                <Breadcrumb breadcrumbTitle={breadcrumb} />

                <div className="hero-inner-container servizi">
                    <div className="page-container">
                        <div className="hero-row">
                            <div className="contact-information hero-column hero-col-6">
                                <div className="services-list">
                                    <div className="list-header row">
                                        <div className="hero-col-3">
                                            <img src={"/assets/images/icona-fattura.png"} alt="service icon"/>
                                        </div>
                                        <div className="hero-col-8">
                                            <h3 style={{fontSize: 17 + 'px'}}>{breadcrumb} B2B</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <p>La Legge di Stabilità 2018 ha introdotto l’obbligo generalizzato della fattura elettronica per tutte le operazioni (cessioni di beni e prestazioni di servizi) fra soggetti identificati ai fini IVA in Italia esercenti imprese, arti o professioni, sia nei confronti di imprese/artisti/professionisti (Business- to-Business o B2B) sia nei confronti di privati (Business-to-Consumer o B2C).</p>
                                        <p>L’obbligo di utilizzare la fattura elettronica implica che le fatture cartacee non saranno più ammesse per le operazioni nazionali e si considereranno come non emesse, esponendo i cedenti/prestatori nonché i cessionari/destinatari a rilevanti sanzioni (Fatture emesse dal 90% al 180% dell’IVA; Fatture ricevute il 100% dell’IVA)</p>
                                        {/*<div className="subheader-button">*/}
                                            {/*<button>SCARICA IL PDF INFORMATIVO</button>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>

                            <div className="contact-form hero-column hero-col-6">
                                <ContactForm title="COMPILA UN MODULO PER MAGGIORI INFORMAZIONI" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="textarea-clearfix hidden"></div>
            <span className="contact"></span>
            <Footer type="secondary"/>
        </div>
    );
}

export default fatturazioneElettronica;