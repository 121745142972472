import React from "react";
import Breadcrumb from "../layout/Breadcrumb";
import SearchForInfo from "../layout/SearchForInfo";
import ContactForm from "../layout/ContactForm";
import { scrollToTop, changeTitle } from "../../assets/js/helper";
import Footer from "../footer";

const LavoroPage = (props) => {

    const breadcrumb = "LAVORO";
    const activePage = "lavoro";

    scrollToTop();
    changeTitle(activePage);

	return (
		<div>
			<div className="page-container-hero">
				<Breadcrumb breadcrumbTitle={breadcrumb} />
				<div className="hero-inner-container servizi">
                        <div className="container">
                            <SearchForInfo />
                        </div>
                        <div className="page-container">
                            <div className="hero-row">
                                <div className="hero-column hero-col-12">
                                    <p>Tramite la nostra agenzia, è possibile richiedere alla Camera di Commercio tutti i documenti di cui si ha bisogno, dalle visure ai bilanci, dai certificati ai documenti aziendali. Con Pratiche 2M potrai ottenere tutto in pochissimo tempo, evitando qualsiasi spostamento. Contattaci per ricevere maggiori informazioni. </p>
                                </div>
                            </div>
                            <div className="hero-row">
                                <div className="contact-information hero-column hero-col-6">
                                    <div className="services-list">
                                        <div className="list-header row">
                                            <div className="hero-col-12">
                                                <h3>MODELLI  ORGANIZZATIVI CON RIFERIMENTO AL  D.LGS 231/01</h3>
                                            </div>
                                            <p>Il Decreto legislativo 8 giugno 2001 n.231, ha introdotto nell’ordinamento italiano la responsabilità degli enti per illeciti conseguenti alla commissione di un reato. Si tratta di un sistema di responsabilità autonomo, caratterizzato da presupposti e conseguenze distinti da quelli previsti per la responsabilità penale della persona fisica.</p>
                                        	<p>In particolare, l’ente può essere ritenuto responsabile se, prima della commissione del reato da parte di un soggetto a esso funzionalmente collegato, non aveva adottato ed efficacemente attuato modelli di organizzazione e gestione idonei a evitare reati della specie di quello verificatosi.</p>
                                        	<p>L’accertamento dell’illecito previsto dal decreto231 espone l’ente all’applicazione di gravi sanzioni, che ne colpiscono il patrimonio, l’immagine e l’attività stessa.</p>
                                        	<p>Il D.lgs. 231/2001 individua nel Modello organizzativo, correttamente elaborato, adottato ed aggiornato, lo strumento privilegiato per esimere una società dalla responsabilità amministrativa da reato. Un modello organizzativo e di gestione ai sensi del D.lgs 231/2001 è insieme di protocolli che regolano e definiscono la struttura aziendale e la gestione dei suoi processi sensibili e che, se correttamente applicato riduce il rischio di commissione di illeciti penali.</p>
                                        	<h3>I Protocolli tipici di un Modello organizzativo sono:</h3>
                                        	<ul>
                                        		<li>Il Codice etico;</li>
												<li>Il sistema Disciplinare;</li>
												<li>L’OdV- Organismo di Vigilanza;</li>
												<li>L’insieme delle procedure specifiche per le aree sensibili al rischio di reato.</li>
                                        	</ul>
                                        	<p>La Gestione del rischio di Reato, attuata attraverso il Modello Organizzativo 231, è un attività di tipo preventivo che individua le aree aziendali in cui i dirigenti e/o i dipendenti potrebbero scegliere di agire nell’interesse o a vantaggio dell’azienda, ledendo però al contempo una serie di interessi diffusi e giuridicamente rilevanti come ad esempio la salute dei lavoratori , gli interessi e il patrimonio della pubblica Amministrazione, la privacy di dipendenti o soggetti terzi, l’ambiente ,i diritti umani ecc</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="contact-form hero-column hero-col-6">
                                	<ContactForm title="COMPILA UN MODULO PER MAGGIORI INFORMAZIONI" />
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="textarea-clearfix hidden"></div>
                <span className="contact"></span>
                <Footer type="secondary"/>
			</div>
		</div>
	);
};

export default LavoroPage;