export const headData = {
    "home": [
        {
            "title": "Home"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3",
        },
        {
            "name" : "description",
            "content" : "Some description.."
        },
        {
            "name" : "author",
            "content" : "John Doe"
        },
    ],
    "imprese": [
        {
            "title": "Imprese"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, imprese"
        },
        {
            "name" : "description",
            "content" : "Some description.. imprese"
        },
        {
            "name" : "author",
            "content" : "John Doe imprese"
        },
    ],
    "privati": [
        {
            "title": "Privati"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, privati"
        },
        {
            "name" : "description",
            "content" : "Some description.. privati"
        },
        {
            "name" : "author",
            "content" : "John Doe privati"
        },
    ],
    "professionisti": [
        {
            "title": "Professionisti"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, professionisti"
        },
        {
            "name" : "description",
            "content" : "Some description.. professionisti"
        },
        {
            "name" : "author",
            "content" : "John Doe professionisti"
        },
    ],
    "chi-siamo": [
        {
            "title": "Chi siamo"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, Chi siamo"
        },
        {
            "name" : "description",
            "content" : "Some description.. Chi siamo"
        },
        {
            "name" : "author",
            "content" : "John Doe Chi siamo"
        },
    ],
    "contatti": [
        {
            "title": "Contatti"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, Contatti"
        },
        {
            "name" : "description",
            "content" : "Some description.. Contatti"
        },
        {
            "name" : "author",
            "content" : "John Doe Contatti"
        },
    ],
    "domande-frequenti": [
        {
            "title": "Domande frequenti"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, faq"
        },
        {
            "name" : "description",
            "content" : "Some description.. faq"
        },
        {
            "name" : "author",
            "content" : "John Doe faq"
        },
    ],
    "agenzia-delle-entrate": [
        {
            "title": "Agenzia delle Entrate"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, agenzia delle entrate"
        },
        {
            "name" : "description",
            "content" : "Some description.. agenzia delle entrate"
        },
        {
            "name" : "author",
            "content" : "John Doe agenzia delle entrate"
        },
    ],
    "agenzia-per-la-riscossione": [
        {
            "title": "Agenzia per la Riscossione"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, agenzia per la riscossione"
        },
        {
            "name" : "description",
            "content" : "Some description.. agenzia per la riscossione"
        },
        {
            "name" : "author",
            "content" : "John Doe agenzia per la riscossione"
        },
    ],
    "tribunale": [
        {
            "title": "Tribunale"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, Tribunale"
        },
        {
            "name" : "description",
            "content" : "Some description.. Tribunale"
        },
        {
            "name" : "author",
            "content" : "John Doe Tribunale"
        },
    ],
    "comune": [
        {
            "title": "Comune"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, Comune"
        },
        {
            "name" : "description",
            "content" : "Some description.. Comune"
        },
        {
            "name" : "author",
            "content" : "John Doe Comune"
        },
    ],
    "servizi-digitali": [
        {
            "title": "Servizi digitali"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, Servizi digitali"
        },
        {
            "name" : "description",
            "content" : "Some description.. Servizi digitali"
        },
        {
            "name" : "author",
            "content" : "John Doe Servizi digitali"
        },
    ],
    "servizi-professionali": [
        {
            "title": "Servizi professionali"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, Servizi professionali"
        },
        {
            "name" : "description",
            "content" : "Some description.. Servizi professionali"
        },
        {
            "name" : "author",
            "content" : "John Doe Servizi professionali"
        },
    ],
    "camera-di-commercio": [
        {
            "title": "Camera di Commercio"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, Camera di Commercio"
        },
        {
            "name" : "description",
            "content" : "Some description.. Camera di Commercio"
        },
        {
            "name" : "author",
            "content" : "John Doe SCamera di Commercio"
        },
    ],
    "fatturazione-elettronica": [
        {
            "title": "Fatturazione Elettronica"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, Fatturazione Elettronica"
        },
        {
            "name" : "description",
            "content" : "Some description.. Fatturazione Elettronica"
        },
        {
            "name" : "author",
            "content" : "John Doe Fatturazione Elettronica"
        },
    ],
    "pony-express": [
        {
            "title": "Pony Express"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, Fatturazione Elettronica"
        },
        {
            "name" : "description",
            "content" : "Some description.. Fatturazione Elettronica"
        },
        {
            "name" : "author",
            "content" : "John Doe Fatturazione Elettronica"
        },
    ],
    "volantinaggio": [
        {
            "title": "Volantinaggio"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, Fatturazione Elettronica"
        },
        {
            "name" : "description",
            "content" : "Some description.. Fatturazione Elettronica"
        },
        {
            "name" : "author",
            "content" : "John Doe Fatturazione Elettronica"
        },
    ],
    "servizi-editoriali": [
        {
            "title": "Servizi Editoriali"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, Fatturazione Elettronica"
        },
        {
            "name" : "description",
            "content" : "Some description.. Fatturazione Elettronica"
        },
        {
            "name" : "author",
            "content" : "John Doe Fatturazione Elettronica"
        },
    ],
    "lavoro": [
        {
            "title": "Lavoro"
        },
        {
            "name" : "keywords",
            "content" : "key1, key2, key3, Fatturazione Elettronica"
        },
        {
            "name" : "description",
            "content" : "Some description.. Fatturazione Elettronica"
        },
        {
            "name" : "author",
            "content" : "John Doe Fatturazione Elettronica"
        },
    ],
};