import React from 'react';
import Breadcrumb from "../layout/Breadcrumb";
import SearchForInfo from "../layout/SearchForInfo";
import ContactForm from "../layout/ContactForm";
import Footer from '../footer';
import { scrollToTop, changeTitle } from '../../assets/js/helper';
import { Link } from 'react-router-dom';

const serviziProfessionali = (props) => {

    const breadcrumb = "SERVIZI PROFESSIONALI";
    const activePage = "servizi-professionali";

    scrollToTop();
    changeTitle(activePage); 

    const servicesList = {
        "servizi-professionali": {
            icon: "professionali.png",
            list: [
                "Ricorsi",
                "Legge 3/2012 sovraindebitamento",
                "Marchi e brevetti",
                "DVR - sicurezza sul lavoro"
            ]
        }
    };

    const renderList = () => servicesList[activePage].list.map((item, i) => (
        <div key={i}>
            <li> <span>{ item }</span></li>
        </div>
    ));

    return(
        <div>
            <div className="page-container-hero">
                <Breadcrumb breadcrumbTitle={breadcrumb} />

                <div className="hero-inner-container servizi">
                    <div className="container">
                        <SearchForInfo />
                    </div>
                    <div className="page-container">
                        <div className="hero-row">
                            <div className="hero-column hero-col-12">
                                <p>L'esperienza e la presenza di collaboratori qualificati rendono Pratiche 2M un partner davvero affidabile anche nel disbrigo di pratiche come marchi e brevetti, ricorsi  e ciò che concerne la sicurezza sul lavoro. Rivolgiti a noi e riceverai tutto il supporto di cui hai bisogno.</p>
                            </div>
                        </div>
                        <div className="hero-row">
                            <div className="contact-information hero-column hero-col-6">
                                <div className="services-list">
                                    <div className="list-header row">
                                        <div className="hero-col-3">
                                            <img src={"/assets/images/" + servicesList[activePage].icon} alt="service icon"/>
                                        </div>
                                        <div className="hero-col-8">
                                            <h3>SERVIZI</h3>
                                        </div>
                                    </div>

                                    <ul>
                                        {renderList()}
                                        <li>
                                            <Link to="/lavoro" style={{color: "#92d050"}}>
                                                MODELLI  ORGANIZZATIVI CON RIFERIMENTO AL  D.LGS 231/01
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="contact-form hero-column hero-col-6">
                                <ContactForm title="COMPILA UN MODULO PER MAGGIORI INFORMAZIONI" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="textarea-clearfix hidden"></div>
            <span className="contact"></span>
            <Footer type="secondary"/>
        </div>
    );
}

export default serviziProfessionali;