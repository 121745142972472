export const Validator = () => ({
    fields: [],
    fields_values: {},
    fields_rules: {},
    fields_errors: {},
    form_is_valid: true,

    addField: function(name, value, validation_rules) {
        this.fields.push(name);
        this.fields_values[name] = value;
        this.fields_rules[name] = validation_rules;
    },

    getValue: function(name) { return this.fields_values[name]; },
    getValues: function() { return this.fields_values;},
    getError: function(name) { return this.fields_errors[name]; },
    getErrors: function() { return this.fields_errors; },

    validate: function() {
        const fields_length = this.fields.length;
        for (let i = 0 ; i < fields_length ; i++) {
            let rules = this.fields_rules[this.fields[i]].split("|");
            let rules_length = rules.length;
            for (let j = 0 ; j < rules_length ; j++) {
                if(this.rules(this.fields[i], rules[j]) === false) { break; }
            }
        }
        return this.form_is_valid;
    },

    rules: function(field_name, rule) {
        if (rule === 'email'){
            const re_email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!re_email.test(this.fields_values[field_name])) {
                this.fields_errors[field_name] = [this.errorMessages("email")];
                this.form_is_valid = false;
                return false;
            }
        } else if (rule.indexOf('min:') > -1) {
            const min_val = parseInt(rule.split(':')[1]);
            if(this.fields_values[field_name].length < min_val){
                this.fields_errors[field_name] = [this.errorMessagesMinMax("min", min_val)];
                this.form_is_valid = false;
                return false;
            }
        } else if (rule.indexOf('max:') > -1) {
            const max_val = parseInt(rule.split(':')[1]);
            if(this.fields_values[field_name].length > max_val){
                this.fields_errors[field_name] = [this.errorMessagesMinMax("max", max_val)];
                this.form_is_valid = false;
                return false;
            }
        } else if (rule === "required") {
            if(this.fields_values[field_name].length === 0) {
                this.fields_errors[field_name] = [this.errorMessages("required")];
                this.form_is_valid = false;
                return false;
            }
        } else if (rule === "checkbox_required") {
            if(!(String(this.fields_values[field_name]) === "true")) {
                this.fields_errors[field_name] = [this.errorMessages("required")];
                this.form_is_valid = false;
                return false;
            }
        } else if (rule === "letters_and_digits") {
            if(!this.fields_values[field_name].match("^[A-Za-z0-9]+$")) {
                this.fields_errors[field_name] = [this.errorMessages("letters_and_digits")];
                this.form_is_valid = false;
                return false;
            }
        } else if (rule === "digits") {
            const re_digits = /^\d+$/;
            if(!re_digits.test(this.fields_values[field_name])) {
                this.fields_errors[field_name] = [this.errorMessages("digits")];
                this.form_is_valid = false;
                return false;
            }
        } else if (rule === "photo") {
            const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
            if (acceptedImageTypes.indexOf(this.fields_values[field_name]['type']) === -1) {
                this.fields_errors[field_name] = [this.errorMessages("photo")];
                this.form_is_valid = false;
                return false;
            }
        } else {
            return true;
        }
    },

    errorMessages: function(rule) {
        const messages = {
            required: "Si prega di compilare questo campo.",
            email: "Formato non invalido.",
            digits: "Questo campo può contenere solo cifre.",
            letters_and_digits: "Solo lettere e cifre.",
            photo: "Estensioni richieste: jpg, jpeg, png, gif."
        };
        return messages[rule];
    },

    errorMessagesMinMax: function(type, value) {
        if (type === "min") {
            return "Dimensione minimo "+String(value)+" caratteri.";
        } else {
            return "Dimensione massima "+String(value)+" caratteri.";
        }
    }

});