import React from 'react';
import Breadcrumb from "../layout/Breadcrumb";
import SearchForInfo from "../layout/SearchForInfo";
import ContactForm from "../layout/ContactForm";
import Footer from '../footer';
import { scrollToTop, changeTitle } from '../../assets/js/helper';

const Tribunale = (props) => {

    const breadcrumb = "TRIBUNALE";
    const activePage = "tribunale";

    scrollToTop();
    changeTitle(activePage);

    const servicesList = {
        "tribunale" : {
            icon: "tribunale.png",
            list: [
                "Casellario giudiziale",
                "Carichi pendenti",
                "Anagrafica delle sanzioni amministrative"
            ]
        }
    };

    const renderList = () => {
        return servicesList[activePage].list.map((item, i) => (
            <div key={i}>
                <li> <span>{item}</span></li>
            </div>
        ));
    };

    
    return (
        <div>
            <div className="page-container-hero">
                <Breadcrumb breadcrumbTitle={breadcrumb} />
            
                <div className="hero-inner-container servizi">
                    <div className="container">
                        <SearchForInfo />
                    </div>
                    <div className="page-container">
                        <div className="hero-row">
                            <div className="hero-column hero-col-12">
                                <p>Con il nostro supporto è possibile ottenere pratiche e documenti anche dal tribunale, come casellari giudiziari, carichi pendenti e notifiche di atti. I nostri collaboratori sono costantemente a vostra disposizione, consentendo a tutti i nostri clienti di ottenere i documenti desiderati in poco tempo.</p>
                            </div>
                        </div>
                        <div className="hero-row">
                            <div className="contact-information hero-column hero-col-6">
                                <div className="services-list">
                                    <div className="list-header row">
                                        <div className="hero-col-3">
                                            <img src={"/assets/images/" + servicesList[activePage].icon} alt="service icon"/>
                                        </div>
                                        <div className="hero-col-8">
                                            <h3>SERVIZI</h3>
                                        </div>
                                    </div>

                                    <ul>
                                        {renderList()}
                                    </ul>
                                </div>
                            </div>

                            <div className="contact-form hero-column hero-col-6">
                                <ContactForm title="COMPILA UN MODULO PER MAGGIORI INFORMAZIONI" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="textarea-clearfix hidden"></div>
            <span className="contact"></span>
            <Footer type="secondary"/>
        </div>
    );
}

export default Tribunale;