export const serviceParents = {
    "agenzia-delle-entrate" : {
        title: "Agenzia di locazione",
        icon: "entrate.png",
    },
    "agenzia-per-la-riscossione": {
        title: "Agenzia per la Riscossione",
        icon: "riscossione.png",
    },
    "comune" : {
        title: "Comune",
        icon: "comune.png",
    },
    "servizi-digitali" : {
        title: "Servizi Digitali",
        icon: "digitali.png",
    },
    "camera-di-commercio": {
        title: "Camera di Commercio",
        icon: "camera.png",
    }
};

export const searchKeywords = [
    {
        service: "Contratti di locazione",
        parent: "agenzia-delle-entrate",
        keywords: ["contratti", "locazione"]
    },
    {
        service: "Contratti di comodato",
        parent: "agenzia-delle-entrate",
        keywords: ["contratti", "comodato"]
    },
    {
        service: "Registrazione atti e verbali",
        parent: "agenzia-delle-entrate",
        keywords: ["registrazione", "atti", "verbali"]
    },
    {
        service: "Apertura partita iva",
        parent: "agenzia-delle-entrate",
        keywords: ["apertura", "partita", "iva"]
    },
    {
        service: "Certificati reddituali",
        parent: "agenzia-delle-entrate",
        keywords: ["certificati", "reddituali"]
    },
    {
        service: "Dichiarazione di successione",
        parent: "agenzia-delle-entrate",
        keywords: ["dichiarazione", "successione"]
    },
    {
        service: "Codice fiscale attribuzione e/o duplicato",
        parent: "agenzia-delle-entrate",
        keywords: ["codice", "fiscale", "attribuzione", "duplicato"]
    },
    {
        service: "Certificato reddituale",
        parent: "agenzia-delle-entrate",
        keywords: ["certificato", "reddituale"]
    },
    {
        service: "Regolarità fiscale",
        parent: "agenzia-delle-entrate",
        keywords: ["regolarità", "regolarita", "fiscale"]
    },
    {
        service: "Estratto di ruolo",
        parent: "agenzia-per-la-riscossione",
        keywords: ["estratto", "ruolo"]
    },
    {
        service: "Relate di notifica",
        parent: "agenzia-per-la-riscossione",
        keywords: ["relate", "notifica"]
    },
    {
        service: "Pagamento cartelle",
        parent: "agenzia-per-la-riscossione",
        keywords: ["pagamento", "cartelle"]
    },
    {
        service: "Rateizzi",
        parent: "agenzia-per-la-riscossione",
        keywords: ["rateizzi"]
    },
    {
        service: "Rimborsi",
        parent: "agenzia-per-la-riscossione",
        keywords: ["rimborsi"]
    },
    {
        service: "Richiesta RAV",
        parent: "agenzia-per-la-riscossione",
        keywords: ["richiesta", "rav"]
    },
    {
        service: "SUAP",
        parent: "comune",
        keywords: ["suap"]
    },
    {
        service: "Certificati di residenza",
        parent: "comune",
        keywords: ["certificati", "residenza"]
    },
    {
        service: "Stato di famiglia",
        parent: "comune",
        keywords: ["stato", "famiglia"]
    },
    {
        service: "Certificato di matrimonio",
        parent: "comune",
        keywords: ["certificato", "matrimonio"]
    },
    {
        service: "Certificato di cittadinanza",
        parent: "comune",
        keywords: ["certificato", "cittadinanza"]
    },
    {
        service: "Certificato di congruità",
        parent: "comune",
        keywords: ["certificato", "congruità", "congruita"]
    },
    {
        service: "Certificato godimento dei diritti politici",
        parent: "comune",
        keywords: ["certificato", "godimento", "diritti", "politici"]
    },
    {
        service: "Iscrizione liste elettorali",
        parent: "comune",
        keywords: ["iscrizione", "liste", "elettorali"]
    },
    {
        service: "PEC",
        parent: "servizi-digitali",
        keywords: ["pec"]
    },
    {
        service: "Firma digitale / CNS",
        parent: "servizi-digitali",
        keywords: ["firma", "digitale", "cns"]
    },
    {
        service: "Smart card",
        parent: "servizi-digitali",
        keywords: ["smart", "card"]
    },
    {
        service: "Aruba Key",
        parent: "servizi-digitali",
        keywords: ["aruba", "key"]
    },
    {
        service: "Codice LEI",
        parent: "servizi-digitali",
        keywords: ["codice", "lei"]
    },
    {
        service: "Fatturazione elettronica",
        parent: "servizi-digitali",
        keywords: ["fatturazione", "elettronica"]
    },
    {
        service: "Conservazione sostitutiva",
        parent: "servizi-digitali",
        keywords: ["conservazione", "sostitutiva"]
    },
    {
        service: "Cessione quote societarie",
        parent: "camera-di-commercio",
        keywords: ["cessione", "quote", "societarie"]
    },
    {
        service: "Deposito bilanci",
        parent: "camera-di-commercio",
        keywords: ["deposito", "bilanci"]
    },
    {
        service: "Conversione bilanci in XBRL",
        parent: "camera-di-commercio",
        keywords: ["conversione", "bilanci", "xblr"]
    },
    {
        service: "Comunicazione cambio amministratore",
        parent: "camera-di-commercio",
        keywords: ["comunicazione", "cambio", "amministratore"]
    },
    {
        service: "Comunicazione cambio sede (nello stesso comune)",
        parent: "camera-di-commercio",
        keywords: ["comunicazione", "cambio", "sede", "nello", "stesso", "comune"]
    },
    {
        service: "Visura camerale ordinaria",
        parent: "camera-di-commercio",
        keywords: ["visura", "camerale", "ordinaria"]
    },
    {
        service: "Visura camerale storica",
        parent: "camera-di-commercio",
        keywords: ["visura", "camerale", "storica"]
    },
    {
        service: "Visura protesti",
        parent: "camera-di-commercio",
        keywords: ["visura", "protesti"]
    },
    {
        service: "Certificati di iscrizione",
        parent: "camera-di-commercio",
        keywords: ["certificati", "iscrizione"]
    },
    {
        service: "Estratto conto diritti camerali",
        parent: "camera-di-commercio",
        keywords: ["estratto", "conto", "diritti", "camerali"]
    },

];