import React from 'react';
import Breadcrumb from "../layout/Breadcrumb";
import SearchForInfo from "../layout/SearchForInfo";
import ContactForm from "../layout/ContactForm";
import Footer from '../footer';
import { scrollToTop, changeTitle } from '../../assets/js/helper';

const CameraDiCommercio = (props) => {

    const breadcrumb = "CAMERA DI COMMERCIO";
    const activePage = "camera-di-commercio";

    scrollToTop();
    changeTitle(activePage);

    const servicesList = {
        "camera-di-commercio" : {
            icon: "camera.png",
            list: [
                "Cessione quote societarie",
                "Deposito bilanci",
                "Conversione bilanci in XBRL",
                "Comunicazione cambio amministratore",
                "Comunicazione cambio sede (nello stesso comune)",
                "Visura camerale ordinaria",
                "Visura camerale storica",
                "Visura protesti",
                "Certificati di iscrizione",
                "Estratto conto diritti camerali"
            ]
        }
    };

    return (
        <div>
            <div className="page-container-hero">
                <Breadcrumb breadcrumbTitle={breadcrumb} />
                <div className="hero-inner-container servizi">
                    <div className="container">
                        <SearchForInfo />
                    </div>
                    <div className="page-container">
                        <div className="hero-row">
                            <div className="hero-column hero-col-12">
                                <p>Tramite la nostra agenzia, è possibile richiedere alla Camera di Commercio tutti i documenti di cui si ha bisogno, dalle visure ai bilanci, dai certificati ai documenti aziendali. Con Pratiche 2M potrai ottenere tutto in pochissimo tempo, evitando qualsiasi spostamento. Contattaci per ricevere maggiori informazioni. </p>
                            </div>
                        </div>
                        <div className="hero-row">
                            <div className="contact-information hero-column hero-col-6">
                                <div className="services-list">
                                    <div className="list-header row">
                                        <div className="hero-col-3">
                                            <img src={"/assets/images/" + servicesList[activePage].icon} alt="service icon"/>
                                        </div>
                                        <div className="hero-col-8">
                                            <h3>SERVIZI</h3>
                                        </div>
                                    </div>

                                    <ul>
                                        {servicesList[activePage].list.map((item, i) => (
                                            <div key={i}>
                                                <li> <span>{item}</span></li>
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div className="contact-form hero-column hero-col-6">
                                <ContactForm title="COMPILA UN MODULO PER MAGGIORI INFORMAZIONI" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="textarea-clearfix hidden"></div>
            <span className="contact"></span>
            <Footer type="secondary"/>
        </div>
    );
};

export default CameraDiCommercio;